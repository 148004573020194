import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import RequestList from "./components/RequestList";
import RequestOverview from "./components/RequestOverview";

function App() {
	return (
		<div className="m-6">
			<Router>
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route exact path="/request" element={<RequestList />} />
					<Route exact path="/overview" element={<RequestOverview />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
