import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { fetchSongs, deleteSong } from "../store";
import Skeleton from "./Skeleton";
import Button from "./Button";
import { useThunk } from "../hooks/use-thunk";
import { GoTrashcan } from "react-icons/go";

function RequestOverview() {
	const [doDeleteSong, isLoadingRemainSongs, isLoadingRemainSongsError] =
		useThunk(deleteSong);

	const [doFetchSongs, isLoadingSongs, isLoadingSongsError] =
		useThunk(fetchSongs);

	const { data } = useSelector((state) => {
		return state.songs;
	});
	const navigate = useNavigate();
	const showDeleteButton = true;

	useEffect(() => {
		const user = localStorage.getItem("user");
		if (user === null) {
			navigate("/");
		} else {
			doFetchSongs();
		}
	}, [doFetchSongs, navigate]);

	const delSong = (id) => {
		doDeleteSong(id);
	};

	const handleClick = () => {
		const user = localStorage.getItem("user");
		if (user === '"Willem J. Ritmeester"') {
			navigate("/overview");
		} else {
			navigate("/request");
		}
	};

	let content;

	if (isLoadingSongs || isLoadingRemainSongs) {
		content = <Skeleton times={3} className="h-10 w-full" />;
	} else if (isLoadingSongsError || isLoadingRemainSongsError) {
		content = <div>De wachtrij met verzoekjes is nog leeg.</div>;
	} else {
		content = data.map((song) => {
			let extraInfo = `[${song.Year}]`;
			return (
				<div key={uuidv4()} className="mb-2 ml-1 border rounded">
					<div className="flex p-2 justify-between items-center cursor-pointer">
						{song.Artist} - {song.Title} {extraInfo} <br />
						{song.Omschrijving}: {song.userName}
						{showDeleteButton ? (
							<Button
								type="Submit"
								onClick={() => {
									delSong(song.Id);
								}}>
								<GoTrashcan />
							</Button>
						) : null}
					</div>
				</div>
			);
		});
	}

	return (
		<form>
			<div className="ml-1 mb-1 font-bold text-blue-500">
				Lijst met verzoekjes:
			</div>
			{content}

			<div className="flex flex-col items-center p-2 cursor-pointer">
				<Button
					className="border-blue-500 bg-blue-500 text-white rounded-full"
					type="Submit"
					onClick={handleClick}>
					Refresh
				</Button>
			</div>
		</form>
	);
}

export default RequestOverview;
