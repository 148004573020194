import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Button from "./Button";
import TextInput from "./TextInput";
import pic1 from "../assets/images/vinyldj.jpg";
import pic2 from "../assets/images/vinyldj1.jpg";
import pic3 from "../assets/images/vinyldj2.jpg";

function Home() {
	const [user, setUser] = useState("");
	const navigate = useNavigate();
	let appUser = localStorage.getItem("user");
	const pic = [pic1, pic2, pic3];
	const changeName = false;

	const randomNumberInRange = (min, max) => {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};

	const photo = pic[randomNumberInRange(0, 2)];

	useEffect(() => {
		if (appUser !== null) {
			setUser(appUser);
		}
	}, [appUser]);

	const handleChangeName = () => {
		localStorage.removeItem("user");
		setUser("");
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const user = e.target.user.value;
		if (user === "Willem J. Ritmeester") {
			navigate("/overview");
		} else if (user.length > 2) {
			localStorage.setItem("user", JSON.stringify(user));
			localStorage.setItem("userId", uuidv4());
			e.target.user.value = "";
			navigate("/request");
		}
	};

	const handleClick = () => {
		if (user === "Willem J. Ritmeester") {
			navigate("/overview");
		} else {
			navigate("/request");
		}
	};

	return (
		<div className="mt-28">
			<div className="flex flex-col m-2 items-center">
				<h1 className="text-xl mb-6">Plaatje aanvragen?</h1>
				<img
					className="rounded-full w-96 h-96"
					src={photo}
					alt="foto Vinyl DJ Willem"
				/>
				<div className="flex flex-col m-2 items-center ">
					<p>Ik ben Vinyl DJ Willem.</p>
					{appUser === null ? (
						<p>Hoe heet jij?</p>
					) : (
						<p> Welkom terug {JSON.parse(appUser)}</p>
					)}
				</div>
				{appUser === null ? (
					<form
						className="flex flex-col m-2 items-center "
						onSubmit={handleSubmit}>
						<TextInput
							onChange={(e) => setUser(e.target.value)}
							value={user}
							name="user"
							label=""
							type={"text"}
							placeholder={"Voer je naam in"}
						/>
						<div className="mb-2 mt-2"></div>

						<Button type="submit" primary rounded>
							Versturen
						</Button>
					</form>
				) : (
					<div className="flex flex-col m-2 items-center">
						<Button primary rounded className="mx-6" onClick={handleClick}>
							Naar verzoekjes
						</Button>
						{changeName ? (
							<div
								className="mt-2 text-xs text-blue-500 cursor-pointer"
								onClick={handleChangeName}>
								naam wijzigen?
							</div>
						) : null}
					</div>
				)}
			</div>
		</div>
	);
}

export default Home;
