import axios from "axios";

const searchMusic = async (song) => {
	const response = await axios.get("https://api.discogs.com/database/search", {
		headers: {
			Authorization:
				"Discogs key=ruULixnElygFnawNHilg,secret=azJTTPcYKrsYIagNwosoQrKboZZbOgdQ",
		},
		params: {
			q: `${song.artist}-${song.title}`,
		},
	});

	let response2 = response;
	//console.log(response.data.results);
	if (response.data.results < 1) {
		response2 = await axios.get("https://api.discogs.com/database/search", {
			headers: {
				Authorization:
					"Discogs key=ruULixnElygFnawNHilg,secret=azJTTPcYKrsYIagNwosoQrKboZZbOgdQ",
			},
			params: {
				q: song.artist,
				title: song.title,
			},
		});
	}
	//console.log(response2.data.results);
	const results = response2.data.results.filter((result) => {
		if (result.type === "master") {
			return result;
		}
		return null;
	});
	//console.log(results);
	const results2 = results.filter((result) => {
		const res = result.title.split(" - ");
		//console.log(res[0], res[1]);
		if (
			res[0].toLowerCase() === song.artist.toLowerCase() &&
			res[1].toLowerCase() === song.title.toLowerCase()
		) {
			return result;
		}
		return null;
	});
	if (results2.length === 0 && results.length > 0) {
		//console.log("geen single");
		const results2 = results.filter((result) => {
			const res = result.title.split(" - ");

			if (res[0].toLowerCase() === song.artist.toLowerCase()) {
				return result;
			}
			return null;
		});
		//console.log(results2);
		return results2[0];
	}

	return results2[0];
};

export { searchMusic };
